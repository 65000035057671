<template>
	<div>
		<!-- <BaseEmpty width="97"> -->
		<BaseEmpty>
			<template>
				<div class="filter-wrap mb-1">
					<div
						v-if="possuiPermissao('GER_I_RECEIVE')"
						class="filter mr-2 icon-add-transaction"
						@click="showToReceive"
						title="Lançar Conta a Receber"
					>
						<font-awesome-icon icon="money-check-dollar" class="fa-xl"/>
					</div>
					<div
						v-if="possuiPermissao('GER_R_RECEIVE')"
						class="filter mr-2 icon-payment"
						v-bind:class="!itemSelected ? 'filter-disable' : ''"
						@click="showReceive('All')"
						title="Baixar Selecionados"
					>
						<CreditCardIcon toolTip="Baixar selecionados"/>
					</div>
					<div
						v-if="possuiPermissao('GER_D_RECEIVE')"
						class="filter mr-2 icon-delete"
						v-bind:class="!itemSelected ? 'filter-disable' : ''"
						@click="confirmaApagar('All')"
						title="Apagar Selecionados"
					>
						<Trash2Icon/>
					</div>
					<div
						class="filter"
						v-bind:class="[showFiltersForm ? 'filter-active' : '']"
						@click="filterDropDownActive"
						title="Alterar Filtros"
					>
						<FilterIcon/>
					</div>
				</div>

				<b-modal
					ref="modalToReceive"
					hide-footer
					:title="titleModal"
					size="md"
					no-overflow
					hide-no-focus="true"
				>
					<div class="modal-edicao mb-3 modal-edicao-label">
						<b-row class="filters-orientation">
							<b-col class="text-parcela mb-3">
								<label class="label-conta mb-0">Data Lançamento</label>
								<div class="d-flex">
									<datepicker
										class="w-100"
										placeholder=""
										id="data-lancamento"
										input-class="bg-white"
										v-model="toReceive_date"
										:format="formatter"
										:language="ptBR"
										:bootstrap-styling="true"
										data-cy="Data Lançamento"
									/>
								</div>
							</b-col>
							<b-col class="d-block text-parcela mb-3">
								<span class="label-conta">
									Cliente
								</span>
								<b-form-select
									class="modal-edicao-input"
									v-model="toReceive_customer"
									name="customer"
									:options="selectCustomerToReceive"
									:disabled="loading"
									v-validate="{ required: true }"
									:state="validateState('customer')"
								>
									<template slot="first">
										<option :value="null">
											Selecione
										</option>
									</template>
								</b-form-select>
							</b-col>
							<b-col class="text-parcela">
								<span class="label-conta">
									Categoria
								</span>
								<b-form-select
									class="modal-edicao-input"
									v-model="toReceive_idCategory"
									name="categoryIncome"
									:options="optionModalCategory"
									:disabled="loading"
									v-validate="{ required: true }"
									:state="validateState('categoryIncome')"
								>
									<template slot="first">
										<option :value="null">
											Selecione
										</option>
									</template>
								</b-form-select>
							</b-col>
						</b-row>
						<b-row class="mt-3 filters-orientation">
							<b-col class="d-block text-parcela mb-3">
								<span class="label-conta">Valor</span>
								<b-form-input
									name="valor"
									class="modal-edicao-input number-class"
									v-model="toReceive_amount_string"
									autocomplete="off"
									placeholder=""
									v-validate="'required|noZeroCurrency'"
									:state="validateState('valor')"
									@input="setInstallments(toReceive_amount_string)"
								/>
							</b-col>
							<b-col class="d-block text-parcela">
								<span class="label-cont">Parcelas</span>
								<b-form-input
									name="installments"
									class="modal-edicao-input number-class"
									type="number"
									v-model="toReceive_installments"
									autocomplete="off"
									placeholder=""
									min="1"
									v-validate="{ required: true, min_value: 1 }"
									:state="validateState('installments')"
									@input="setInstallments()"
									:disabled="editing"
								/>
							</b-col>
							<b-col class="text-parcela">
								<label class="label-conta mb-0">Intervalo em días</label>
								<b-form-input
									name="interval"
									class="modal-edicao-input number-class"
									type="number"
									v-model="toReceive_interval_days"
									autocomplete="off"
									placeholder=""
									min="1"
									v-validate="{ required: true, min_value: 1 }"
									:state="validateState('interval')"
									@input="setInstallments()"
									:disabled="editing"
								/>
							</b-col>
						</b-row>
						<section
							v-if="!editing"
							class="label-table mt-3"
						>
							<div class="mt-2" style="padding: 3px 0;">
								<b-row
									class="ml-4 mr-5"
								>
									<b-col
										cols="3"
									>
										Parcela
									</b-col>
									<b-col
										cols="4"
									>
										Vencimento
									</b-col>
									<b-col
										cols="5"
									>
										Valor
									</b-col>
								</b-row>	
							</div>
						</section>
						<section
							v-if="!editing"
							class="table-parcelas"
						>
							<b-row
								v-for="i in parseInt(toReceive_installments)" :key="i"
								class="ml-2 mb-1 mr-5 justify-items"
							>
								<b-col
									cols="3" class="d-flex label-parcela"
								>
									<span class="label-parcela"># {{ i }}</span>
								</b-col>
								<b-col
									cols="4" class="d-flex label-parcela"
								>
									<span class="label-parcela">{{ toReceive_date_installments[i - 1] }}</span>
								</b-col>
								<b-col
									cols="5" class="d-flex label-parcela"
								>
									<b-form-input
										:name="`amount-${i}`"
										class="modal-edicao-input number-class"
										v-model="toReceive_amount_installments_string[i - 1]"
										autocomplete="off"
										placeholder=""
										:state="validateState(`amount-${i}`)"
										v-validate="'required|noZeroCurrency'"
										@input="setNewValue(toReceive_amount_installments_string[i - 1], i - 1)"
										:disabled="parseInt(toReceive_installments) == 1"
									/>
								</b-col>
							</b-row>
						</section>
						<b-row class="mt-3 filters-orientation">
							<b-col class="d-block text-parcela">
								<span class="label-conta">Observações</span>
								<b-form-input
									name="comments"
									class="modal-edicao-input"
									v-model="toReceive_comments"
									autocomplete="off"
									placeholder=""
									v-validate="{ required: false }"
									:state="validateState('comments')"
								/>
							</b-col>
						</b-row>
					</div>
					<b-button class="ml-1 mt-3 float-left btn-modal btn-cancel" @click="fecharModal('modalToReceive')">Cancelar</b-button>
					<b-button class="mt-3 float-left btn-modal btn-limpar" @click="limparModal()">Limpar</b-button>
					<b-button
						class="mt-3 ml-1 float-right btn-modal btn-salvar"
						@click="save"
						:disabled="invalidForm"
					>
						Salvar
					</b-button>
				</b-modal>

				<b-modal
					ref="modalReceive"
					hide-footer
					title="Recebimento"
					size="lg"
					no-overflow
					hide-no-focus="true"
				>
					<div class="modal-edicao mb-3 modal-edicao-label">
						<b-row>
							<b-col class="text-parcela">
								<label class="label-parcela">Data Recebimento</label>
								<div class="d-flex">
									<datepicker
										class="w-100"
										placeholder=""
										id="receiveExecuteDate"
										input-class="bg-white"
										v-model="receiveExecuteDate"
										:format="formatter"
										:language="ptBR"
										:bootstrap-styling="true"
										data-cy="receiveExecuteDate"
									/>
								</div>
							</b-col>
							<b-col class="text-parcela">
								<label class="label-parcela">
									Conta
								</label>
								<b-form-select
									class="modal-edicao-input"
									v-model="receiveExecuteAccount"
									name="receiveExecuteAccount"
									:options="optionModalAccount.filter(account => account.permitePagamento)"
									:disabled="loading"
									v-validate="{ required: true }"
									:state="validateState('receiveExecuteAccount')"
								>
									<template slot="first">
										<option :value="null">
											Selecione
										</option>
									</template>
								</b-form-select>
							</b-col>
							<b-col class="text-parcela">
								<label class="label-parcela">Valor</label>
								<b-form-input
									name="receiveExecuteAmount"
									class="modal-edicao-input number-class"
									v-model="receiveExecuteAmount_string"
									:disabled="true"
								/>
							</b-col>
						</b-row>
						<b-row class="mt-2">
							<b-col cols="12" class="d-block text-parcela">
								<label class="label-parcela">Observações</label>
								<b-form-input
									name="receiveExecuteComments"
									class="modal-edicao-input"
									v-model="receiveExecuteComments"
									autocomplete="off"
									placeholder=""
									v-validate="{ required: false }"
									:state="validateState('receiveExecuteComments')"
								/>
							</b-col>
						</b-row>
					</div>
					<b-button class="ml-1 mt-3 float-left btn-modal btn-cancel" @click="fecharModal('modalReceive')">Cancelar</b-button>
					<b-button class="mt-3 float-left btn-modal btn-limpar" @click="limparModal(true)">Limpar</b-button>
					<b-button
						class="mt-3 ml-1 float-right btn-modal btn-salvar"
						@click="executeReceive"
						:disabled="invalidForm"
					>
						Salvar
					</b-button>
				</b-modal>

				<div v-if="showFiltersForm" class="filters-form content-card">
					<div>
						<b-row
							class="filters-orientation filters-section"
						>
							<b-col class="filter-item">
								<label>
									Estado
								</label>
								<vue-multi-select
									class="multi-100 mb-1 style-select"
									:disabled="loading"
									v-model="selectedStatusToReceive"
									search
									searchPlaceholder="Pesquisar"
									:options="{ multi: true, labelName: 'nome' }"
									:selectOptions="selectStatusToReceive"
									data-cy="status"
									:btnLabel="() => (selectedStatusToReceive.length > 1 ? `${selectedStatusToReceive[0].nome}...` : selectedStatusToReceive[0] && selectedStatusToReceive[0].nome) || 'Selecione'"
								/>
							</b-col>
							<b-col class="filter-item">
								<label>Categoria</label>
								<vue-multi-select
									class="multi-100 mb-1 style-select"
									:disabled="loading"
									v-model="selectedCategory"
									search
									searchPlaceholder="Pesquisar"
									:options="{ multi: true, labelName: 'text' }"
									:selectOptions="selectCategory"
									data-cy="account"
									:btnLabel="() => (selectedCategory.length > 1 ? `${selectedCategory[0].text}...` : selectedCategory[0] && selectedCategory[0].text) || 'Selecione'"
								/>
							</b-col>
							<b-col class="filter-item">
								<label>
									Cliente
								</label>
								<vue-multi-select
									name="customer"
									class="multi-100 mb-1 style-select"
									:disabled="loading"
									v-model="selectedCustomer"
									search
									searchPlaceholder="Pesquisar"
									:options="{ multi: true, labelName: 'text' }"
									:selectOptions="optionModalCustomer"
									data-cy="customer"
									:btnLabel="() => (selectedCustomer.length > 1 ? `${selectedCustomer[0].text}...` : selectedCustomer[0] && selectedCustomer[0].text) || 'Selecione'"
								/>
							</b-col>
							<b-col class="filter-item">
								<label>
									Data da pesquisa
								</label>
								<vue-multi-select
									class="multi-100 mb-1 style-select"
									:disabled="loading"
									v-model="selectedDataPesquisa"
									search
									searchPlaceholder="Pesquisar"
									:options="{ multi: false, labelName: 'nome' }"
									:selectOptions="selectDataPesquisa"
									data-cy="status"
									:btnLabel="() => (selectedDataPesquisa.length > 1 ? `${selectedDataPesquisa[0].nome}...` : selectedDataPesquisa[0] && selectedDataPesquisa[0].nome) || 'Selecione'"
								/>
							</b-col>
						</b-row>
						<b-row
							class="filters-orientation filters-section-seconds"
						>
							<b-col class="filter-item">
								<label>Data inicial</label>
								<div class="d-flex">
									<datepicker
										class="w-100"
										placeholder="Início"
										id="data-inicial-historica"
										input-class="bg-white"
										v-model="inicio"
										:format="formatter"
										:language="ptBR"
										:bootstrap-styling="true"
										data-cy="Data Inicial"
										@input="setFilterDateEnd(inicio)"
									/>
								</div>
							</b-col>
							<b-col class="filter-item">
								<label>Data final</label>
								<div class="d-flex">
									<datepicker
										class="w-100"
										placeholder="Fim"
										id="data-final-historica"
										input-class="bg-white"
										v-model="fim"
										:format="formatter"
										:language="ptBR"
										:bootstrap-styling="true"
										data-cy="Data Final"
									/>
								</div>
							</b-col>
							<b-col class="filter-item">
								<label>
									Observações
								</label>
								<input
									id="comments"
									class="form-control time-pick"
									v-model="comments"
								/>
							</b-col>
						</b-row>
						<div class="mt-4 search-button" @click="findToReceive(true)">
							<SearchIcon/>
						</div>
					</div>
				</div>
				<b-row class="row-1">
				</b-row>
			</template>
		</BaseEmpty>
		<Base style="margin-top: 0px">
			<template>
				<div class="container-head">
					<div v-if="linhas.length" class="container-pages">
						<b-pagination
							id="page"
							v-model="currentPage"
							:total-rows="pagination.count"
							:per-page="pagination.limit.value"
						></b-pagination>
					</div>
				</div>
				<div v-if="linhas.length" class="head-row mb-4">
					<span class="text-head-row ml-1">
						Resultado {{ pagination.offset + 1 }}-{{ pagination.offset + linhas.length }} de {{ pagination.count }}
					</span>
					<b-form
						class="text-head-row width-pagination-items">
						<label class="mr-4">
							Itens por página
						</label>
						<b-form-select
							class="mr-1 mb-2 width-pagination"
							v-model="pagination.limit.value"
							name="itens-por-pagina"
							:options="optionsItensPorPag"
							@input="changeItensByPage()"
							:disabled="loading"
						/>
					</b-form>
				</div>
				<div v-if="linhas.length" class="table-responsive">
					<DataTable
						class="data-table"
						:loading="loading"
						:colunas="colunas"
						:linhas="linhas"
						:errMsg="errMsg"
						:nosearch="true"
						:nofilters="true"
						name="transacoes"
						:hasPagination="true"
						:noedit="true"
						:acoes="true"
						:permissaoEdit="possuiPermissao('GER_U_RECEIVE')"
						:permissaoDelete="possuiPermissao('GER_D_RECEIVE')"
						:permissaoRecover="possuiPermissao('GER_D_RECEIVE')"
						:permissaoPayment="possuiPermissao('GER_R_RECEIVE')"
						@clickDelete="confirmaApagar"
						@clickEdit="showToReceive"
						@clickRecover="toRecover"
						@clickPayment="showReceive"
						:selectItem="possuiPermissao('GER_M_AGENDAMENTOS')"
						:alignColumns="alignColumnsTable"
						@clickSortTableBy="(v) => sortTableBy(v)"
						:state="{
							sortBy: sortTable.sortBy,
							sortAsc: sortTable.order === 1,
							query: ''
						}"
						:async="true"
						@clickCheck="showButonsTop"
					/>
				</div>
				<div v-else class="alert alert-warning" role="alert">
					{{ errMsg }}
				</div>
			</template>
		</Base>
	</div>
</template>

<script>
	import dayjs from "dayjs";
	import "dayjs/locale/pt-br";
	import Datepicker from "vuejs-datepicker";
	import { ptBR } from "vuejs-datepicker/dist/locale";

	import BaseEmpty from "@/templates/BaseEmpty";
	import Base from "@/templates/Base.vue";
	import DataTable from "@/components/DataTable";
	import { getDateMonthEnd, numberFormatted, stringToNumber, stringToDateUTC, validateNumber } from "@/helpers/common";
	import { ToReceiveService } from "../../services/toReceive";
	import { AccountService } from "../../services/account";
	import { possuiPermissao } from "../../helpers/permissions";
	import { hasFieldsWithErrors } from "@/helpers/validators";
	import { CategoryExpensesService } from "../../services/categoryExpenses";
	import { STATUS_RECEIVE } from "../../helpers/enums";
	import "./styles.css";
	import { CustomerService } from "../../services/customer";

	export default {
		components: {
			BaseEmpty,
			Base,
			DataTable,
			Datepicker
		},

		inject: ["parentValidator"],

		data () {
			return {
				ready: true,
				loading: false,
				updating: false,
				ptBR,
				titleModal: "",

				// filters
				inicio: null,
				fim: null,
				comments: "",
				selectCategory: [],
				selectDataPesquisa: [
					{ nome: "Lançamento", value: 0 },
					{ nome: "Recebimento", value: 1 }
				],
				selectedDataPesquisa: [{ nome: "Lançamento", value: 0 }],

				toReceive: [],
				showFiltersForm: false,
				showAddModal: false,
				selectTransaction: [],

				transactionSelecionada: {},
				optionModalCategory: [],
				optionModalCustomer: [],

				toReceive_comments: "",
				toReceive_idCategory: null,
				toReceive_date: new Date(),
				toReceive_date_receive: new Date(),
				toReceive_amount: 0,
				toReceive_amount_string: "0,00",
				toReceive_customer: null,
				toReceive_installments: 1,
				toReceive_date_installments: [],
				toReceive_amount_installments: [],
				toReceive_amount_installments_string: [],
				toReceive_interval_days: 1,
				toReceive_idToReceive: null,
				toReceive_idToReceiveDocument: null,

				income_value: 0,
				selectedCategory: [],
				selectedStatusToReceive: [{ nome: "Em aberto", value: 0 }],
				selectedCustomer: [],

				errMsg: "Nenhuma conta a receber!",
				colunas: [
					"Cliente",
					"Categoria",
					"Data",
					"A Receber",
					"Valor",
					"Status",
					"Observações"
				],
				alignColumnsTable: [
					"left",
					"left",
					"left",
					"left",
					"right",
					"left",
					"left"
				],

				selectStatusToReceive: [
					{ nome: "Em aberto", value: 0 },
					{ nome: "Baixadas", value: 1 },
					{ nome: "Nulas", value: 2 }
				],
				selectCustomerToReceive: [],

				linhas: [],
				pagination: {
					count: 0,
					page: 1,
					offset: 0,
					limit: {
						value: 25
					},
					pages: 1
				},
				currentPage: 1,
				optionsItensPorPag: [
					{
						value: 5,
						text: "5 Items"
					},
					{
						value: 25,
						text: "25 Items"
					},
					{
						value: 50,
						text: "50 Items"
					},
					{
						value: 75,
						text: "75 Items"
					},
					{
						value: 100,
						text: "100 Items"
					}
				],

				invalidForm: true,
				editing: false,
				permitePagamento: null,
				selectItem: false,
				optionModalAccount: [],

				rendaExecuteDate: new Date(),
				rendaExecuteAccount: null,
				rendaExecuteAmount: numberFormatted(0),
				rendaExecuteComments: "",

				toReceiveService: new ToReceiveService(),
				accountService: new AccountService(),
				categoryService: new CategoryExpensesService(),
				customerService: new CustomerService(),
				possuiPermissao,
				sortTable: {
					sortBy: 0,
					order: -1
				},
				itemSelected: false,

				config: {
					timeout: 2000,
					showProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true
				},
				receiveExecuteDate: new Date(),
				receiveExecuteAccount: null,
				receiveExecuteAmount: numberFormatted(0),
				receiveExecuteAmount_string: "0,00",

				receiveExecuteComments: "",
				receiveExecute: [],
			};
		},

		watch: {
			currentPage () {
				this.changePage();
			},

			filters () {
				this.linhas = [];
			}
		},

		async mounted () {
			await Promise.all([
				// this.setFilter(),
				this.findAccount(),
				this.findCategory(),
				this.findCustomer()
			]);
			await this.findToReceive();
		},

		methods: {
			async findCategory () {
				const result = await this.categoryService.listCategoryExpenses({offset: 0, limit: null}) || {};
				this.selectCategory = result.rows?.reduce((acc, item) => {
					if (item.income)
						acc.push({ text: item.description, value: item.idCategoryExpense });
					
					return acc;
				}, []);

				this.optionModalCategory = result.rows?.reduce((acc, item) => {
					if (item.income)					
						acc.push({ text: item.description, value: item.idCategoryExpense });
					
					return acc;
				}, []);
			},

			sortTableBy (v) {
				this.sortTable = v;
				this.findToReceive();
			},

			changePage () {
				this.pagination.page = this.currentPage;
				this.findToReceive();
			},

			changeItensByPage () {
				this.pagination.page = 1;
				this.findToReceive();
			},

			limparModal (receiving) {
				this.toReceive_idToReceive = null;
				this.toReceive_idToReceiveDocument = null;
				this.toReceive_comments = "";
				this.toReceive_idCategory = null;
				this.toReceive_date = new Date();
				this.toReceive_date_receive = new Date();
				this.toReceive_customer = null;

				this.toReceive_amount_installments = [];
				this.toReceive_date_installments = [];
				this.toReceive_amount_installments_string = [];
				this.toReceive_installments = 1;
				this.toReceive_interval_days = 1;

				this.receiveExecuteDate = new Date();
				this.receiveExecuteAccount = null;
				this.receiveExecuteAmount = numberFormatted(0);
				this.receiveExecuteComments = "";
				
				if (!receiving) {
					this.toReceive_amount = 0;
					this.toReceive_amount_string = "0,00";
					this.receiveExecuteAmount_string = "0,00";
				}
			},

			fecharModal (modal) {
				this.$refs[modal].hide();
				this.limparModal();
			},

			setFilter () {
				const fim = new Date();
				const month = fim.getMonth();
				const year = fim.getFullYear();
				const ultimoDiaMes = new Date(year, month + 1, 0).getDate();
				fim.setDate(ultimoDiaMes);
				const inicio = new Date();
				inicio.setDate("1");
				this.inicio = inicio;
				this.fim = fim;
			},

			setFilterDateEnd (date) {
				this.fim = getDateMonthEnd(date);
			},

			async findToReceive (newFind) {
				if (newFind) {
					const exit = this.currentPage !== 1;
					this.pagination.page = 1;
					this.currentPage = 1;
					if (exit) return;
				}

				const limit = this.pagination.limit.value;
				this.pagination.offset = (this.pagination.page - 1) * limit;
				const offset = this.pagination.offset;
				const sortTable = this.sortTable;

				const filters = {
					limit,
					offset,
					initDate: this.inicio ? dayjs(this.inicio).locale("pt-br").format("YYYY-MM-DD") : "",
					endDate: this.fim ? dayjs(this.fim).locale("pt-br").format("YYYY-MM-DD") : "",
					comments: this.comments,
					idsCategoryIncome: this.selectedCategory,
					idsCustomer: this.selectedCustomer,
					dataPesquisa: this.selectedDataPesquisa,
					statusToReceive: this.selectedStatusToReceive || null,
					sortTable
				};

				this.loading = true;
				const result = await this.toReceiveService.getToReceiveByParams(filters) || {};
				this.toReceive = result.rows;
					
				this.linhas = result.rows.reduce((acc, c) => [
					...acc,
					{
						idToReceive: c.idToReceive,
						selected: false,
						notItemActions: Boolean(c.status === "PAID"),
						notItemSelect: Boolean(c.status !== "ACTIVE"),
						isCanceled: Boolean(c.status === "CANCELED"),
						cols: [
							c.nameCustomer || "",
							c.nameCategory || "",
							this.formatterSimple(c.date),
							this.formatterSimple(c.toReceiveDate),
							numberFormatted(c.amount),
							STATUS_RECEIVE[c.status],
							c.comments
						]
					}
				], []);

				this.pagination.count = result.count || 0;
				this.pagination.pages = this.pagination.count > limit ? Math.ceil(this.pagination.count / limit) : 1;
				this.loading = false;
				if (this.showFiltersForm)
					this.showFiltersForm = false;
			},

			formatter (date) {
				return dayjs(date).locale("pt-br").format("D [de] MMMM [de] YYYY");
			},

			formatterSimple (date, withHour) {
				if (withHour)
					return dayjs(date).locale("pt-br").format("DD-MM-YYYY HH:mm:ss");

				return dayjs(date).locale("pt-br").format("DD-MM-YYYY");
			},

			validateState (ref) {
				this.invalidForm = hasFieldsWithErrors(this.veeFields);
				if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated))
					return !this.veeErrors.has(ref);

				return null;
			},

			async showToReceive (item_) {
				let item = {};

				if (item_.idToReceive) {
					[ item ] = this.toReceive.filter(c => c.idToReceive === item_.idToReceive);
				}

				const {
					amount,
					comments,
					date,
					toReceiveDate,
					idToReceive,
					idCategory,
					idCustomer,
					idToReceiveDocument
				} = item;

				if (!idToReceive) {
					this.titleModal = "Adicionar Conta a Receber";
					this.editing = false;
				} else {
					this.titleModal = `Editar Conta a Receber`;
					this.editing = true;

					this.toReceive_idToReceive = idToReceive;
					this.toReceive_idToReceiveDocument = idToReceiveDocument;
					this.toReceive_comments = comments;
					this.toReceive_date = stringToDateUTC(date);
					this.toReceive_date_receive = stringToDateUTC(toReceiveDate);
					this.toReceive_amount = amount;
					this.toReceive_amount_string = numberFormatted(amount).replace(/\./g, "");
					this.toReceive_idCategory = idCategory || "";
					this.toReceive_customer = idCustomer || "";
				}
				this.invalidForm = true;
				this.$refs.modalToReceive.show();
			},

			async save () {
				const toReceiveDataInstallments = [];
				for (let idx = 0; idx < this.toReceive_installments; idx++) {
					toReceiveDataInstallments.push({
						toReceiveDate: this.toReceive_date_installments[idx],
						amount: this.toReceive_amount_installments[idx]
					});
				}

				const payload = {
					comments: this.toReceive_comments,
					idCategory: this.toReceive_idCategory,
					date: this.toReceive_date,
					toReceiveDataInstallments,
					amount: this.toReceive_amount,
					idToReceive: this.toReceive_idToReceive,
					idCustomer: this.toReceive_customer,
					interval: parseInt(this.toReceive_interval_days),
					idToReceiveDocument: this.toReceive_idToReceiveDocument
				};

				if (!payload.amount) {
					const messagem = "Verifique se os seguintes campos estão preenchidos corretamente: ";
					let messagem_1 = !payload.amount ? "- Valor a receber. " : "";

					await this.$swal({
						title: "Salvar",
						text: messagem.concat(messagem_1),
						type: "error",
						showCancelButton: false,
						confirmButtonColor: "rgb(132, 170, 194)",
						confirmButtonText: "Ok"
					});
					this.invalidForm = true;
					return;
				}

				this.$snotify.async("Aguarde...", "Salvando", async () => {
					try {
						if (payload.idToReceive)
							await this.toReceiveService.updateToReceive({payload});
						else
							await this.toReceiveService.createToReceive({payload});

						this.fecharModal("modalToReceive");
						this.findToReceive();
						this.limparModal();

						return {
							title: "Sucesso!",
							body: "Transação salva com sucesso.",
							config: this.config
						};
					} catch (error) {
						throw {
							title: "Falha!",
							body: "Erro ao tentar salvar a Transação.",
							config: this.config
						};
					}
				});
			},

			async confirmaApagar (item_) {
				if (typeof (item_) === "string") {
					this.apagarVarios();
					return;
				}

				const [ item ] = this.toReceive.filter((gu) => gu.idToReceive === item_.idToReceive);

				const deleteConfirmation = (await this.$swal({
					title: "Excluir",
					text: "Tem certeza que deseja deletar a transação?",
					type: "error",
					showCancelButton: true,
					cancelButtonColor: "#A6A8AB",
					confirmButtonColor: "#DD6B55",
					imageWidth: 50,
					imageHeight: 50,
					cancelButtonText: "Não",
					confirmButtonText: "Sim",
					reverseButtons: true
				})).value;

				if (deleteConfirmation)
					this.apagar(item.idToReceive);
			},

			async apagar (idToReceive) {
				this.$snotify.async("Aguarde...", "Apagando", async () => {
					try {
						const payload = { idsToReceive: [idToReceive] };
						await this.toReceiveService.deleteToReceive({payload});
						this.findToReceive();

						return {
							title: "Sucesso!",
							body: "Transação deletada com sucesso.",
							config: this.config
						};
					} catch (error) {
						const msgError = {
							title: "Falha!",
							body: "Erro ao tentar deletar a transação.",
							config: this.config
						};

						if (error.response.status === 400) {
							// config.timeout = 5000;
							msgError.body = "Não é possível deletar a transação.";
						}

						throw msgError;
					}
				});
			},

			filterDropDownActive () {
				this.showFiltersForm = !this.showFiltersForm;
			},

			AddDropDownActive () {
				this.showAddModal = !this.showAddModal;
			},

			async apagarVarios () {
				const idsToReceive = this.linhas.reduce((acc, item) => {
					if (item.selected)
						return [ ...acc, item.idToReceive ];

					return acc;
				}, []);

				if (!idsToReceive.length) return;
				
				const deleteConfirmation = (await this.$swal({
					title: "Excluir",
					text: "Tem certeza que deseja inutilizar a transação selecionada?",
					type: "error",
					showCancelButton: true,
					cancelButtonColor: "#A6A8AB",
					confirmButtonColor: "#DD6B55",
					imageWidth: 50,
					imageHeight: 50,
					cancelButtonText: "Não",
					confirmButtonText: "Sim",
					reverseButtons: true
				})).value;

				if (!deleteConfirmation)
					return;

				this.$snotify.async("Aguarde...", "Apagando", async () => {
					try {
						const payload = { idsToReceive };
						await this.toReceiveService.deleteToReceive({payload});
						this.findToReceive();

						return {
							title: "Sucesso!",
							body: "Conta a receber deletada com sucesso.",
							config: this.config
						};
					} catch (error) {
						const msgError = {
							title: "Falha!",
							body: error.response.data.message || "Erro ao tentar deletar a conta a receber.",
							config: this.config
						};

						if (error.response.status === 400) {
							//config.timeout = 5000;
							msgError.body = "Não é possível deletar a conta a receber.";
						}

						throw msgError;
					}
				});
			},

			async updateValue (value) {
				this.toReceive_amount_string = await validateNumber(value);
				this.toReceive_amount = this.toReceive_amount_string.replace(",", ".");
			},

			showButonsTop () {
				this.itemSelected = this.linhas.some(linha => linha.selected);
			},

			async toRecover (item_) {
				const [ item ] = this.toReceive.filter((gu) => gu.idToReceive === item_.idToReceive);
				if (item.isCanceled) return;

				if (item.notItemActions) {
					await this.$swal({
						title: "Recuperar",
						text: "Conta a receber já baixada, não pôde ser recuperada!",
						type: "error",
						showCancelButton: false,
						imageWidth: 50,
						imageHeight: 50,
						confirmButtonColor: "rgb(132, 170, 194)",
						confirmButtonText: "Ok"
					});
					return;
				}

				const recoverConfirmation = (await this.$swal({
					title: "Recuperar",
					text: "Tem certeza que deseja recuperar a conta a rerceber?",
					type: "error",
					showCancelButton: true,
					cancelButtonColor: "#A6A8AB",
					confirmButtonColor: "#DD6B55",
					imageWidth: 50,
					imageHeight: 50,
					cancelButtonText: "Não",
					confirmButtonText: "Sim",
					reverseButtons: true
				})).value;

				if (recoverConfirmation)
					this.recover(item.idToReceive);

			},

			async recover (idToReceive) {
				this.$snotify.async("Aguarde...", "Recuperando", async () => {
					try {
						const payload = { idsToReceive: [idToReceive] };
						await this.toReceiveService.recoverToReceive({payload});
						this.findToReceive();

						return {
							title: "Sucesso!",
							body: "Conta a receber recuperada com sucesso.",
							config: this.config
						};
					} catch (error) {
						const msgError = {
							title: "Falha!",
							body: "Erro ao tentar recuperar o agendamento.",
							config: this.config
						};

						if (error.response.status === 400) {
							// config.timeout = 5000;
							msgError.body = "Não é possível recuperar o agendamento.";
						}

						throw msgError;
					}
				});
			},

			async findAccount () {
				const result = await this.accountService.listAccount({offset: 0, limit: null}) || {};
				this.selectAccount = result.rows?.map(item => ({ nome: item.description, value: item.idAccount })) || [];
				this.optionModalAccount = result.rows?.map(item => ({
					text: item.description,
					value: item.idAccount,
					permiteParcelas: item.accountClass.closeMonthly,
					permitePagamento: item.accountClass.automaticPay,
					closingDay: item.closingDay,
					payDay: item.payDay,
					closeNextDayWeekend: item.closeNextDayWeekend,
					balance: item.balance
				})) || [];
			},

			showReceive (item_) {
				if (item_.notItemActions) return;

				let receive = [];
				if (typeof item_ === "string") {
					receive = this.linhas.reduce((acc, item) => {
						if (item.selected) {
							return [ ...acc, { idToReceive: item.idToReceive, valor: stringToNumber(item.cols[4]) }];
						}
						return acc;
					}, []);
					if (!receive.length) return;
				} else {
					receive = [{ idToReceive: item_.idToReceive, valor: stringToNumber(item_.cols[4]) }];
				}
				let valorTotal = 0;
				receive.forEach(item => valorTotal = valorTotal + item.valor);
				this.receiveExecute = receive.map(item => item);
				this.receiveExecuteAmount = parseFloat(parseFloat(valorTotal).toFixed(2));
				this.receiveExecuteAmount_string = numberFormatted(valorTotal);

				this.invalidForm = true;
				this.$refs.modalReceive.show();

			},

			async executeReceive () {
				const baixaConfirmation = (await this.$swal({
					title: "Recebimento",
					text: "Tem certeza que deseja executar o recebimento?",
					type: "error",
					showCancelButton: true,
					cancelButtonColor: "#A6A8AB",
					confirmButtonColor: "#DD6B55",
					cancelButtonText: "Não",
					confirmButtonText: "Sim",
					reverseButtons: true
				})).value;

				if (!baixaConfirmation)
					return;

				const payload = {
					idsToReceive: this.receiveExecute,
					receivedDate: this.receiveExecuteDate,
					idAccount: this.receiveExecuteAccount,
					receivedAmount: this.receiveExecuteAmount,
					comments: this.receiveExecuteComments
				};

				this.$snotify.async("Aguarde...", "Executando recebimento", async () => {
					try {
						await this.toReceiveService.executeReceive({payload});

						this.fecharModal("modalReceive");
						this.findToReceive();
						this.limparModal();
						this.findAccount();

						return {
							title: "Sucesso!",
							body: "Baixas realizadas com sucesso.",
							config: this.config
						};
					} catch (error) {
						const msgError = error.response.data.message || "Erro ao tentar executar baixas.";
						throw {
							title: "Falha!",
							body: msgError,
							config: this.config
						};
					}
				});
			},

			async findCustomer () {
				const result = await this.customerService.listCustomer({offset: 0, limit: null}) || {};
				this.selectCustomerToReceive = result.rows?.reduce((acc, item) => {
					let description = item.description;
					if (item.identityDocument)
						description = description.concat(` - ${item.identityDocument}`);
				
					acc.push({ text: description, value: item.idCustomer });
					
					return acc;
				}, []);

				this.optionModalCustomer = result.rows?.reduce((acc, item) => {				
					let description = item.description;
					if (item.identityDocument)
						description = description.concat(` - ${item.identityDocument}`);
				
					acc.push({ text: description, value: item.idCustomer });
					
					return acc;
				}, []);
			},

			async setInstallments (value) {
				if (value) {
					await this.updateValue(value);
				}

				let valorParcela = parseFloat((this.toReceive_amount / this.toReceive_installments).toFixed(2));
				let somaTotal = 0;
				this.toReceive_amount_installments = [];
				this.toReceive_date_installments = [];
				this.toReceive_amount_installments_string = [];

				const dayInterval = this.toReceive_interval_days;
				const dateNextPayment = new Date(this.toReceive_date);

				for (let parcela = 1 ; parcela <= parseInt(this.toReceive_installments) ; parcela++) {
					if (parcela === parseInt(this.toReceive_installments))
						valorParcela = parseFloat((this.toReceive_amount - somaTotal).toFixed(2));

					somaTotal = somaTotal + valorParcela;
					this.toReceive_amount_installments.push(valorParcela);
					this.toReceive_amount_installments_string.push(numberFormatted(valorParcela).replace(/\./g, ""));
					dateNextPayment.setDate(dateNextPayment.getDate() + parseInt(dayInterval));
					this.toReceive_date_installments.push(dayjs(dateNextPayment).locale("pt-br").format("DD-MM-YYYY"));
				}
			},

			setNewValue (amount, index) {
				if (amount) {
					this.toReceive_amount_installments_string[index] = validateNumber(amount);
					this.toReceive_amount_installments[index] = this.toReceive_amount_installments_string[index].replace(",", ".");
				} else {
					return;
				}

				let somaTotal = 0;
				this.toReceive_amount_installments.forEach(amount => {
					const valorParcela = parseFloat(amount);

					somaTotal = somaTotal + valorParcela;
				})
				this.toReceive_amount = somaTotal.toFixed(2);
				this.toReceive_amount_string = numberFormatted(this.toReceive_amount).replace(/\./g, "");
			},
		}
	};
</script>
