import Axios from "axios";

import environment from "@/environment";
import { RequestInterceptor } from "./request.interceptor";

export class CustomerService {
	axios;

	constructor () {
		const interceptor = new RequestInterceptor();
		this.axios = Axios.create({ baseURL: `${environment.API}/v1` });
		this.axios.interceptors.request.use(interceptor.addAuthorizationHeader);
	}

	async listCustomer (filters) {
		const response = await this.axios.get("customer/listCustomer", { params: { filters }});
		return response.data;
	}

	async upsertCustomer (payload) {
		const response = await this.axios.post("customer/upsertCustomer", payload);
		return response.data;
	}

	async deleteCustomer (id) {
		const response = await this.axios.delete("customer/deleteCustomer", { params: id });
		return response.data;
	}
}
