import Axios from "axios";

import environment from "@/environment";
import { RequestInterceptor } from "./request.interceptor";

export class ToReceiveService {
	axios;

	constructor () {
		const interceptor = new RequestInterceptor();
		this.axios = Axios.create({ baseURL: `${environment.API}/v1` });
		this.axios.interceptors.request.use(interceptor.addAuthorizationHeader);
	}

	async getToReceiveByParams (filters) {
		const response = await this.axios.get("toReceive/getToReceiveByParams", { params: { filters } });
		return response.data;
	}

	async createToReceive (payload) {
		const response = await this.axios.post("toReceive/createToReceive", payload);
		return response.data;
	}

	async updateToReceive (payload) {
		const response = await this.axios.patch("toReceive/updateToReceive", payload);
		return response.data;
	}

	async deleteToReceive (payload) {
		const response = await this.axios.patch("toReceive/deleteToReceive", payload);
		return response.data;
	}

	async recoverToReceive (payload) {
		const response = await this.axios.patch("toReceive/recoverToReceive", payload);
		return response.data;
	}

	async executeReceive (payload) {
		const response = await this.axios.patch("toReceive/executeReceive", payload);
		return response.data;
	}
}
